<template>
  <div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <h2 class="text-xl font-medium mr-auto border-b pb-5">
            <app-i18n code="user.assign.title"></app-i18n>
          </h2>
          <section class="relative">
            <!-- <div
              style="width: 100%; height: 80vh"
              class="flex justify-center"
              v-if="loading"
            >
              <LoadingIcon icon="grid" color="#2D3748" style="width: 100px" />
            </div> -->
            <div v-if="model">
              <div class="grid grid-cols-12 gap-x-5 mt-5 items-center">
                <h4
                  class="text-lg font-medium leading-none items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('units.fields.project') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <v-select
                    @option:selected="selectedProject"
                    :options="projectOptions || []"
                    :label="labelLocalized"
                    v-model="model.project"
                    class="mt-5 select-center"
                    style="width: 250px"
                    title="Select item"
                    :loading="projectsLoading"
                  >
                    <template #no-options>
                      <div>
                        {{
                          isRTL
                            ? 'لا يوجد مشاريع اضف مشروع اولا'
                            : 'No projects create one first'
                        }}
                      </div>
                    </template>
                    <template #spinner="{ loading }">
                      <div v-if="loading" class="vs__spinner"></div> </template
                  ></v-select>
                </div>
              </div>
              <div
                class="grid grid-cols-12 gap-x-5 mt-5 items-center"
                v-show="selectedProjectId && selectedProjectId.length > 0"
              >
                <h4
                  class="text-lg font-medium leading-none flex items-center mr-2 lg:col-span-2"
                >
                  {{ i18n('user.assign.units') }}
                </h4>
                <div class="lg:col-span-4 col-span-12 lg:mt-0 mt-2">
                  <v-select
                    :options="unitOptions || []"
                    label="code"
                    v-model="model.unit"
                    class="select-center"
                    style="width: 250px"
                    title="Select item"
                    :loading="unitsLoading"
                  >
                    <template #no-options>
                      <div>
                        {{
                          isRTL
                            ? 'لا يوجد وحدات اضف وحدات اولا'
                            : 'No units create one first'
                        }}
                      </div>
                    </template>
                    <template #spinner="{ loading }">
                      <div v-if="loading" class="vs__spinner"></div> </template
                  ></v-select>
                </div>
              </div>
              <div class="flex justify-center items-center mt-10">
                <AppButton
                  type="button"
                  class="btn bg-theme-31 text-white cursor-pointer"
                  :class="!loading ? 'w-24' : ''"
                  :disabled="loading"
                  :loading="loading"
                  @click="doSubmit"
                >
                  <strong>{{ i18n('common.save') }}</strong>
                  <template v-slot:loading>
                    <app-i18n code="common.loading"></app-i18n>
                    <LoadingIcon
                      icon="three-dots"
                      color="#FFFFFF"
                      style="margin: 0 4px"
                    />
                  </template>
                </AppButton>
                <button
                  type="button"
                  class="btn bg-theme-32 text-black w-24 ml-3 mr-3 cursor-pointer"
                  @click="doCancel"
                >
                  <app-i18n code="common.cancel"></app-i18n>
                </button>
              </div>
            </div>
          </section>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Message from '@/shared/message/toastify'

export default {
  props: {
    id: {
      type: String,
      required: false
    },
    type: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      unitOptions: [],
      projectOptions: null,
      selectedProjectId: null,
      model: null,
      errorMessage: null
    }
  },
  computed: {
    ...mapGetters({
      isRTL: 'layout/isRTL',
      loading: 'user/form/findLoading',
      language: 'layout/currentLanguageCode',
      projects: 'project/list/rows',
      projectsLoading: 'project/list/loading',
      units: 'unit/list/rows',
      unitsLoading: 'unit/list/loading'
    }),
    labelLocalized() {
      return this.isRTL ? 'nameAr' : 'nameEn'
    }
  },
  created() {
    document.title = this.i18n('routes.app') + ' | ' + this.i18n('routes.users')
  },
  watch: {
    isRTL() {
      document.title =
        this.i18n('routes.app') + ' | ' + this.i18n('routes.users')
    }
  },
  async mounted() {
    this.model = {
      unit: '',
      project: '',
      clientId: this.id
    }
    await this.doFetchProjects({
      pagination: {
        firstPage: true,
        limit: 1000,
        action: 'next',
        orderBy: 'createdAt',
        sortType: 'desc'
      }
    })
    this.projectOptions = this.projects.map((project) => ({
      ...project,
      nameAr: project.name.ar,
      nameEn: project.name.en
    }))
    // await this.doFetchUnits({
    //   pagination: {
    //     firstPage: true,
    //     limit: 1000,
    //     action: 'next',
    //     orderBy: 'createdAt',
    //     sortType: 'desc'
    //   }
    // })
    // this.unitOptions = this.units
  },
  methods: {
    ...mapActions({
      doFetchProjects: 'project/list/doFetch',
      doFetchUnits: 'unit/list/doFetch',
      doAssignOwned: 'user/form/doAssignOwned',
      doAssignMembered: 'user/form/doAssignMembered'
    }),
    i18n(key, args) {
      return this.$t(key, args)
    },
    isFormValid() {
      if (this.model.clientId.length === 0) {
        this.errorMessage = 'notifications.create.popup.emptyFields'
        return false
      }
      if (this.model.unit.id.length === 0) {
        this.errorMessage = 'notifications.create.popup.emptyFields'
        return false
      }
      return true
    },
    doCancel() {
      this.$router.back()
    },
    async selectedProject(val) {
      this.selectedProjectId = val.id
      await this.doFetchUnits({
        filter: {
          key: 'projectId',
          operator: 'equal',
          value: val.id
        },
        pagination: {
          firstPage: true,
          limit: 0,
          action: 'next',
          orderBy: 'createdAt',
          sortType: 'desc'
        }
      })
      this.unitOptions = this.units
    },
    async doSubmit() {
      if (!this.isFormValid()) {
        const ERROR = this.i18n(this.errorMessage)
        return Message.error(ERROR)
      }
      const { unit, clientId } = this.model
      if (this.type === 'assignMembered') {
        await this.doAssignMembered({
          unitId: unit.id,
          clientId
        })
      } else if (this.type === 'assignOwned') {
        await this.doAssignOwned({
          unitId: unit.id,
          clientId
        })
      }
    }
  }
}
</script>
<style lang="scss">
.tom-select {
  .ts-input,
  .ts-dropdown {
    width: 12rem !important;
  }
}
.dropzone {
  .dz-preview {
    .dz-details {
      font-size: 12px !important;
      padding: 0em 1em !important;
    }
    .dz-image {
      width: 80px !important;
      height: 80px !important;
    }
  }
}
.loader {
  width: 30px;
  height: 30px;
  margin: 10px auto;
  border-top: 5px solid #292929;
  border-right: 5px solid #efefef;
  border-bottom: 5px solid #efefef;
  border-left: 5px solid #efefef;
  border-radius: 100px;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
</style>
